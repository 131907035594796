<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="ma-2" color="info" dark v-bind="attrs" v-on="on">
          Add Fund
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Add Fund for : {{ user.userName }} - {{ user.fullName }}
        </v-card-title>

        <v-card-text class="pt-5">
          <v-text-field v-model="amount" label="Amount" filled></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="pink" @click="FundUserAccount()"> Process </v-btn>
          <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
      <script>
export default {
  props: ["user", "userType"],
  data() {
    return {
      dialog: false,
      amount: "",
    };
  },
  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
  },
  methods: {
    FundUserAccount() {
      let payload = {
        token: this.getToken,
        userId: this.user._id,
        amount: this.amount,
      };
      this.$store.dispatch("FundUserAccount", payload);

      this.dialog = false;
    },
  },
};
</script>