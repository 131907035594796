<template>
  <div class="main-one">
    <v-data-table
      :headers="dessertHeaders"
      :items="getAllUser"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="_id"
      show-expand
      class="elevation-1"
      :items-per-page="20"
      hide-default-footer
      :loading="TableLoading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:item.litecoin="{ item }">
        <span>{{ DisplayCurrency(item.litecoin) }}</span>
      </template>
      <template v-slot:item.ethereum="{ item }">
        <span>{{ DisplayCurrency(item.ethereum) }}</span>
      </template>
      <template v-slot:item.bitcoin="{ item }">
        <span>{{ DisplayCurrency(item.bitcoin) }}</span>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-card-actions class="ma-5">
            <v-spacer />
            <AddBonus :user="item" />
            <AddFund :user="item" />
            <EnableOrDisableUser userType="User" :user="item" />
            <LoginHistory userType="User" :user="item" />
          </v-card-actions>
          <v-simple-table dark>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>Bitcoin WalletID</td>
                  <td>{{ item.bitcoinWalletId }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>
    </v-data-table>
    <div class="text-center mt-7">
      <v-pagination v-model="page" :length="totalItems" circle></v-pagination>
    </div>
  </div>
</template>

<script>
import currencyFormatter from "currency-formatter";
import LoginHistory from "../components/LoginHistory.vue";
import EnableOrDisableUser from "../components/EnableOrDisableUser.vue";
import AddFund from "../components/AddFund.vue";
import AddBonus from "../components/AddBonus.vue";

export default {
  components: { LoginHistory, EnableOrDisableUser, AddBonus, AddFund },
  data() {
    return {
      page: 1,
      currentPage: 1,
      expanded: [],
      singleExpand: false,
      dessertHeaders: [
        {
          text: "Full Name",
          align: "start",
          sortable: false,
          value: "fullName",
        },
        { text: "User Name", value: "userName", sortable: false },
        { text: "Join", value: "createdAt", sortable: false },
        { text: "Main Balance", value: "mainBalance", sortable: false },
        { text: "Referral Bonus", value: "referralBonus", sortable: false },
        { text: "", value: "data-table-expand", sortable: false },
      ],
    };
  },
  computed: {
    getAllUser() {
      return this.$store.getters.getAllUser;
    },
    totalItems() {
      let total = this.$store.getters.totalItems;
      return Math.ceil(total / 20);
    },
    getToken() {
      return this.$store.getters.getToken;
    },
    TableLoading() {
      return this.$store.getters.TableLoading;
    },
  },
  methods: {
    DisplayCurrency(payload) {
      return currencyFormatter.format(payload, {
        code: "USD",
      });
    },
    GetAllUser() {
      let payload = {
        token: this.getToken,
        page: this.page,
      };
      this.$store.dispatch("GetAllUsers", payload);
    },
  },
  created() {
    this.GetAllUser();
  },
  watch: {
    page: function (val) {
      if (this.page != this.currentPage) {
        this.currentPage = val;
        let payload = {
          page: this.page,
          token: this.getToken,
        };
        this.$store.dispatch("GetAllUsers", payload);
        return;
      }
    },
  },
};
</script>

<style scoped>
.main-one {
  width: 100%;
}
</style>